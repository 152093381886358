body,
nav,
header,
section,
footer,
.container {
  position: relative;
}

.section {
	padding: 4rem 0;
	&.section-white {
		background: #fff;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-gray {
    	background: #f5f5f5;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-black {
		background: #000;
		color: #fff;
		hr, .page-header {
			border-color: rgba(0,0,0,0.1);
		}
	}
	&.section-lg {
  		padding: 6rem 0;
	}
}

#hero {
	padding-top: 2rem;
	@include media-breakpoint-down(md) {
		padding-bottom: 2rem;
		background: url(../images/bg-milky-way.jpg) no-repeat center center;
		background-size: cover;
	}
}

#intro {
	background: url(../images/bg-art.jpg) no-repeat center center;
	background-size: cover;
}

#wisdom {
	padding-bottom: 2rem;
	.container-fluid {
		padding-left: 0;
		padding-right: 0;
	}
}

#contact {
	padding-bottom: 0;
}

.center-vert {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.widget {
  margin-bottom: 2rem;
}

hr {
  margin: 2rem 0;
}

.embed-video {
	padding: 1rem;
	background: #000;
	border-radius: .5rem;
}

.embed-responsive-9by16 {
    padding-bottom: 177.78%;
}

.flipper {
	padding: 2rem 0;
	li {
		width: 100%;
		max-width: 20rem;
		.flipster__item__content {
			padding: 2rem;
			border-radius: $border-radius;
			box-shadow: 0px 0px 1rem rgba(0,0,0,0.1);
			background: #fff;
		}
	}
}