@font-face {
    font-family: 'cartwheelregular';
    src: url('../fonts/sansani_fonts_-_cartwheel-webfont.woff2') format('woff2'),
         url('../fonts/sansani_fonts_-_cartwheel-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

$purple: #561861;

$headings-font-family: 'cartwheelregular';
$headings-line-height: 1;
$headings-color: $purple;

